import React from 'react'
import PortableText from '@sanity/block-content-to-react'
import sanityConfig from '../../../sanity-config.js'

import { Box } from '@mui/material';

import BlockDefault from './BlockDefault';
import BlockImage from './BlockImage';
import BlockByline from './BlockByline';
import BlockSection from './BlockSection';

import BlockLinks from './BlockLinks';
import BlockFiles from './BlockFiles';
import BlockLink from './BlockLink';
// import BlockMark from './BlockMark';

import BlockList from './BlockList';
import BlockListItem from './BlockListItem';


const Block = ({renderContainerOnSingleChild = true, category = {}, serializers = {}, settings = {}, children, component = "article", ...props}) => {

    const Container = ({children}) => {
        return (
            <Box sx={{fontSize: "inherit"}} component={component} {...props}>
                {children}
            </Box>
        )
        
    }

    if (!component) {
        renderContainerOnSingleChild = false
    }

    const { types, marks, list, listItem } = serializers

    const defaultSerializers = {
        container: Container,
        types: {
            block: BlockDefault,
            blockImage: BlockImage,
            blockByline: (props) => <BlockByline settings={settings} {...props} />,
            blockSection: BlockSection,
            blockLinks: BlockLinks,
            blockFiles: BlockFiles,
            undefined: () => <div>No blocks</div>,
            ...types
        },
        list: (props) => <BlockList {...props} category={category} />,
        listItem: (props) => <BlockListItem {...props} category={category} />,
        marks: {
            articleLink: (props) => <BlockLink {...props} />,
            /*
            targetAudience: BlockMark,
            userTasks: BlockMark,
            businessGoals: BlockMark,
            inwardPaths: BlockMark,
            coreContent: BlockMark,
            forwardPaths: BlockMark,
            */
            ...marks
        }
    }

    if (!children) {
        return false
    }

    return (
        <PortableText
            projectId={sanityConfig.api.projectId}
            dataset={sanityConfig.api.dataset}
            renderContainerOnSingleChild={renderContainerOnSingleChild}
            blocks={children}
            serializers={defaultSerializers}
            ignoreUnknownTypes={true}
        />
    )
    
}

export default Block