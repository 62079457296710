import React from 'react'
import { MenuList, MenuListItem, MenuLink } from ".."

const Menu = ({items = [], level = 1, expanded}) => {

    return (
        <MenuList expanded={expanded}>
            {items && items.map((item, index) => {
                const { menu, url } = item

                return (
                    <MenuListItem key={"m"+index}>
                        <MenuLink {...item} level={level} />
                        { url !== "/" && menu && <Menu items={menu} level={level+1} /> }
                    </MenuListItem>
                )
            })}
        </MenuList>
    )
    
}

export default Menu