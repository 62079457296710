import React from 'react'
import { Box, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(theme => ({
    list: {
        display: "flex",
        flexDirection: "column",
        listStyle: "none",
        padding: 0,
        marginTop: "1.5em",
        marginBottom: "1.5em",
        gap: "1em",

        "& > *": {
            flexBasis: 1,
            flexGrow: 1,
            flexShrink: 1,
        }
    }
}));

const CoreModelList = ({type, component = "ul", children, ...props}) => {

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            listStyle: "none",
            padding: 0,
            marginTop: "1.5em",
            marginBottom: "1.5em",
            gap: "1em",
    
            "& > *": {
                flexBasis: 1,
                flexGrow: 1,
                flexShrink: 1,
            }
    
        }}>
            {children}
        </Box>
    )

}

export default CoreModelList