import React from 'react'
import { Box } from '@mui/material';

const ArticleBody = ({children}) => {

    return (
        <Box sx={{
            position: "relative",
            zIndex: 2,
            marginTop: "1.5em",
            marginBottom: "1.5em",
        }}>
            {children}
        </Box>
    )

}


ArticleBody.defaultProps = {
}

export default ArticleBody
