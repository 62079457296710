const palette = {
    text: {
        primary: "#222"
    },
    background: {
        paper: "#fff",
        paperGrey: "#F6F6F6",
        default: "#F6F6F6",

        targetAudience: "#ffd2e7",
        userTasks: "#bcf27d",
        businessGoals: "#afebfb",
        inwardPaths: "#fda078",
        forwardPaths: "#e6b0fa",
        coreContent: "#ffe484",
    },
}

export default palette