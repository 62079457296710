import React from 'react'
import { Menu } from "../"
import { Box } from "@mui/material"

const PageMenu = ({expanded = true, items = []}) => {

    return (
        <Box aria-expanded={expanded} sx={{

            marginBottom: "1em",

            "&[aria-expanded=false]": {
                display: "none"
            },
   
            
            "& > ul": {
                flexDirection: {
                    xs: "column",
                    md: "row"
                },

                "& > li": {
                    flexShrink: 1,
                    flexGrow: 1
                },

                "& > li + li": {
                    marginTop: {
                        xs: "1em",
                        md: 0
                    }
                }
    

            },


            "& > ul > li": {
                borderTop: "1px solid",
                paddingTop: "1em"
            },
            
            "& [aria-current='page']": {
                fontStyle: "italic"
            },
            

        }}>
            <Menu items={items} expanded={expanded} />
        </Box>
    )
    
}

export default PageMenu