import React from 'react'
import { LinkModule, CollapsibleNote } from ".."
import { getFileSize } from "../../utils"


const FileModule = ({asset = {}, ...props}) => {

    const { extension, mimeType, size, originalFilename, url } = asset

    const fileSize = getFileSize(size)

    const description = mimeType + ", " + fileSize

    return (
        <LinkModule title={originalFilename} url={url} description={description} />
    )


}

export default FileModule
