import React from 'react'
import { Box } from '@mui/material';

const ArticleGrid = ({children}) => {

    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
        
            boxSizing: "border-box",
            width: "100%",
            padding: "0 1em",
            marginLeft: "auto",
            marginRight: "auto",

            fontSize: "1em",

            maxWidth: {
                xs: "100%",
                sm: "60ch"
            }
        
        }}>
            {children}
        </Box>
    )
    
}

export default ArticleGrid