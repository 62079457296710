import React from 'react'
import DefaultTheme from '../theme/DefaultTheme'
import { BaseLayout } from "."
import { Typography } from "@mui/material"
import { ArticleBase, ArticleHeader, ArticleFooter, ArticleBody, ArticleGrid, Block, ArticleBodyBlocks, ArticleFooterBlocks, Heading } from '../components'
import { getModelFromData } from "../utils"

import palette from "../theme/theme.palette"

const ArticleLayout = ({data = {}, location = {}}) => {

    const { title, description, body, footer, backgroundColor } = getModelFromData(data)

    const bgColor = backgroundColor && palette.background[backgroundColor] || palette.background["paper"]

    return (
        <DefaultTheme>
            <BaseLayout backgroundColor={bgColor} data={data} location={location}>
                <ArticleBase>
                    <ArticleHeader>
                        <ArticleGrid>
                            <Typography variant="a1" component="h1">{title}</Typography>
                            <Typography variant="a2" component="h2">{description}</Typography>
                        </ArticleGrid>
                    </ArticleHeader>
                    <ArticleBody>
                        <ArticleGrid>
                            <ArticleBodyBlocks>{body}</ArticleBodyBlocks>
                        </ArticleGrid>
                    </ArticleBody>
                </ArticleBase>
            </BaseLayout>
        </DefaultTheme>
    )        

}

export default ArticleLayout
