import React from 'react'
//import { ArticleByline } from '../';

const BlockByline = ({settings = {}, node = {}}) => {

//    const { author } = node
    const {  _createdAt, _updatedAt } = settings
    const { author = {} } = node

    const byline = {
        name: author.name,
        imageUrl: author.image && author.image.asset.url,
        date: _updatedAt || _createdAt
    }

    return <p>byline</p>

    /*
    return (
        <ArticleByline {...byline}>
        </ArticleByline>
    )*/
    
}

export default BlockByline