import React from "react"
import { Box } from '@mui/material';

const PageBase = ({backgroundColor = "#fff", children}) => {

    return (
        <Box sx={{
            backgroundColor: backgroundColor,
            color: (theme) => theme.palette.text.primary,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: {
                xs: 18,
                md: 20,
                lg: 24
            }
        }}>
            {children}
        </Box>
    )

}

export default PageBase