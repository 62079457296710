import React from 'react'
import { Box } from "@mui/material"

const BoxSectionBase = ({expanded = true, sx = {}, onClick, children}) => {

    return (
        <Box data-module="box" component="section" aria-expanded={expanded} onClick={onClick} sx={{
//            backgroundColor: (theme) => theme.palette.background.default,
            border: "1px solid",
            padding: "0 1em",
//            marginTop: "1.5em",
            marginBottom: "1.5em"
        }}>
            {children}
        </Box>
    )


}


export default BoxSectionBase
