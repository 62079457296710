import React from "react"
import { Box, Typography } from '@mui/material';
import { PageMenu } from ".."

const PageFooter = ({menu, children}) => {

    return (
        <Box component="footer" sx={{
            backgroundColor: (theme) => theme.palette.background.coreContent,
            position: "relative",
            padding: "1em",
        }}>

            <Typography variant="h2">Alt om kjernemodellen</Typography>

            { menu && <PageMenu items={menu} expanded={true} />}
            {children}
        </Box>
    )

}

export default PageFooter