import React from "react"

const BlockLink = ({mark, children, ...props}) => {
    const { reference = {} } = mark
    const url = reference && reference.slug && "/" + reference.slug.current

    return (
        <a href={url}>{children}</a>
    )
}

export default BlockLink
