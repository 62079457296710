import React from 'react'
import { ResourceModule, FileModule, LinkModule, ElementsModule } from ".."

import BlockSection from "./BlockSection"

const templates = {
    "linkList": LinkModule,
    "elementsGrid": ElementsModule
}


const BlockLinksItem = (props) => {

    const { _type, url, title, body, description, layout } = props

    const defaultLayout = Object.keys(templates)[0]
    const ListItemTemplate = layout && templates[layout] || templates[defaultLayout]


    if (_type === "method" || _type === "exercise" || _type === "faq" || _type === "example" || _type === "note") {

        return (
            <BlockSection node={{
                ...props,
                title: title,
                layout: layout || "collapsibleNote"
            }} />
        )
        
    }

    if (_type === "resource") {
        return (
            <ResourceModule {...props} />
        )
    }

    if (_type === "file") {
        return (
            <FileModule {...props} />
        )
    }

    return (
        <ListItemTemplate {...props} />
    )

}

export default BlockLinksItem
