import React from 'react'
import { Link } from "gatsby"
import { Typography, Box } from '@mui/material';

const MenuLink = ({selected, title, url = "/", level = 1}) => {

    

    return (
        <Box component={Link} to={url} data-level={level} sx={{
            fontFamily: "inherit",
            fontSize: "1em",
            lineHeight: 1,
            display: "inline",
            color: "inherit",
            textDecoration: "none",

            "&[data-level='1']": {
                fontWeight: "bold"
            }
        }}>
            {title}            
        </Box>
    )    
    
    
}

export default MenuLink