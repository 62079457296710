import React, { useState } from "react"
import { Box } from '@mui/material';

import { PagePath, PageMenu, MenuButton } from ".."

const PageHeader = ({menu, path, children}) => {

    const [expanded, setExpanded] = useState(false)

    const _onToggle = () => {
        setExpanded(!expanded)
    }

    return (
        <Box component="header" sx={{
            position: "relative",
            zIndex: 2,
            margin: "0 1em",
//            padding: "1em 0",
            borderBottom: "1px solid",
    
            "& > * + *": {
//                marginTop: "1em"
            },
        }}>

            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "5em"
            }}>
                { path && <PagePath items={path} /> }
                <MenuButton onClick={_onToggle}>Meny</MenuButton>
            </Box>

            { menu && <PageMenu expanded={expanded} items={menu} /> }

            {children}
        </Box>
    )

}

export default PageHeader