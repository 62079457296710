import React from 'react'
import { Box } from '@mui/material';

const ArticleFooter = ({children}) => {

    return (
        <Box sx={{
            backgroundColor: "#ddd",
            marginTop: "1.5em",
//            marginBottom: "1.5em",
            paddingTop: "1.5em",
            paddingBottom: "1.5em",
        }}>
            {children}
        </Box>
    )
    
}


ArticleFooter.defaultProps = {
}

export default ArticleFooter
