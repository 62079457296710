import React, { useState } from 'react'
import { Typography, Box } from "@mui/material"

import { SectionBase } from ".."

import ExpandIcon from "@mui/icons-material/Add"
import CollapseIcon from "@mui/icons-material/Remove"

const CollapsibleArticle = ({collapsible = false, title, children, ...props}) => {

    const [expanded, setExpanded] = useState(false)

    const _onToggle = () => {
        setExpanded(!expanded)
    }

    if (!collapsible) {
        return (
            <SectionBase expanded={true}>
                <Typography variant="h2">{title}</Typography>
                {children}
            </SectionBase>
        )
    }

    if (!expanded) {
        return (
            <SectionBase expanded={expanded} onClick={_onToggle}>
                <Typography variant="h2" sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <span>{title}</span>
                    <ExpandIcon sx={{fontSize: "inherit"}} />
                </Typography>
            </SectionBase>
        )
    }

    return (
        <SectionBase expanded={expanded}>
            <Typography variant="h2" onClick={_onToggle} sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <span>{title}</span>
                <CollapseIcon sx={{fontSize: "inherit"}} />
            </Typography>

            {children}
        </SectionBase>
    )

}


export default CollapsibleArticle
