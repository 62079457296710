const fontSans = "Work Sans, sans-serif"
const fontSerif = "Source Serif Pro, serif"

const fontSize = 16 // px
// Tell Material-UI what's the font-size on the html element.
// 16px is the default font-size used by browsers.
//const htmlFontSize = 16
//const coef = fontSize / 16;

const typography = {
//    pxToRem: size => `${(size / htmlFontSize) * coef}rem`,
    fontFamily: fontSans,

    a1: {
        fontSize: "2.5em",
        fontWeight: 700,
        lineHeight: 1.25,
        margin: 0,
    },
    a2: {
        fontSize: "2.5em",
        fontWeight: 500,
        lineHeight: 1.25,
        margin: 0,
    },

    h1: {
        fontFamily: fontSans,
        fontSize: "1.5em",
        fontWeight: 700,
        lineHeight: 1.25,
        margin: "1em 0"
    },
    h2: {
        fontFamily: fontSans,
        fontSize: "1.5em",
        fontWeight: 700,
        lineHeight: 1.25,
        margin: "1em 0"
    },
    h3: {
        fontFamily: fontSans,
        fontSize: "1.25em",
        lineHeight: 1.25,
        fontWeight: 600,
        margin: "1em 0"
    },
    h4: {
        fontFamily: fontSans,
        fontSize: "1em",
        fontWeight: 700,
        marginTop: "1.5em",
        marginBottom: 0
    },
    h5: {
        fontFamily: fontSans,
        fontSize: ".75em",
        lineHeight: 1.5,
        fontWeight: 500,
    },
    h6: {
    },
    subtitle1: {
        fontSize: "1em",
        fontFamily: fontSans,
        fontWeight: 600,
        lineHeight: 1.5,
    },
    subtitle2: {
        fontSize: ".875em",
        fontWeight: 600,
        lineHeight: 1.5,
    },
    quote: {
        fontFamily: fontSerif,
        fontSize: "1em",
        fontWeight: 600,
        lineHeight: 1.5,
        marginTop: "1.5em",
        marginBottom: "1.5em",

        "&:before": {
            content: '"«"'
        },

        "&:after": {
            content: '"»"'
        },

        "& strong": {
            fontWeight: 900
        }

    },
    p1: {
        marginTop: ".5em",
        marginBottom: "1em",
    },
    list1: {
        marginTop: "1.5em",
        marginBottom: "1.5em",
    },
    body1: {
        fontSize: "1em",
        lineHeight: 1.5,

        "& strong": {
            fontWeight: 600
        }

    },
    body2: {
        fontFamily: fontSans,
        fontSize: ".875em",
        lineHeight: 1.5,

        "& strong": {
            fontWeight: 600
        }

    },
    author: {
        fontSize: ".875em",
        fontWeight: 500
    },
    caption: {
        fontFamily: fontSerif,
        fontSize: ".875em",
        fontWeight: 700
    },
    credit: {
        fontFamily: fontSerif,
        fontSize: ".875em",
        fontWeight: 500
    },
    overline: {
        fontFamily: fontSans,
        fontSize: 15,
        fontWeight: 'bold',
        lineHeight: 1.5,
        textTransform: "none",
        margin: '.5em 0 .5em',
       
    },
    button: {
        fontFamily: fontSans,

    }
}

export default typography