import React from 'react'
import { Box } from "@mui/material"

const ElementsGrid = ({expanded = true, sx = {}, onClick, children}) => {

    return (
        <Box sx={{
            marginTop: "1.5em",
            marginBottom: "1.5em",

            marginLeft: {
                xs: "-1em",
                md: "-5em"
            },
            marginRight: {
                xs: "-1em",
                md: "-5em"
            },

            gap: {
                xs: 0,
                md: "1em"
            },

            display: "grid",
            /*
            columnGap: "1em",
            rowGap: "1em",
            */
            gridTemplateColumns: {
                xs: "repeat(2, minmax(0, 1fr))",
                sm: "repeat(3, minmax(0, 1fr))"
            }

        }}>
            {children}
        </Box>
    )


}


export default ElementsGrid
