import React from 'react'
import { Box, ListItem } from '@mui/material';

const MenuListItem = ({children}) => {


    return (
        <ListItem component="li" sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: 0,
        }}>
            {children}
        </ListItem>
    )
    
}

export default MenuListItem