import React from 'react'

import BookModule from './BookModule';
import LinkModule from './LinkModule';

const Resource = ({type, ...props}) => {

    if (type == "book") {
        return (
            <BookModule {...props} />
        )
    }

    return (
        <LinkModule {...props} />
    )

}

export default Resource
