import React from 'react'
import { Typography, Box } from '@mui/material'
import { Block, BlockDefault, BlockList } from '../'

const ArticleFooterBlocks = ({children}) => {

    const Label = ({children}) => (
        <Box sx={{
                display: "inline-block",
                backgroundColor: "black",
                padding: ".5em",
                color: "white",
            }}>
                {children}
        </Box>
    )

    const FooterBlock = (props) => {
        const { type, children } = props
        const { style = 'normal' } = props.node

        if (style === 'h1') {
            return <Typography component="h1" variant="h1"><Label>{children}</Label></Typography>
        }

        return <BlockDefault {...props} />
    
    }

    const serializers = {
        types: {
            block: FooterBlock
        },

    }

    return (
        <Block serializers={serializers}>{children}</Block>
    )
    
}

export default ArticleFooterBlocks