import React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { MenuLink } from ".."

const PagePath = ({expanded = false, items = []}) => {

    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{
            fontFamily: "inherit",
            fontSize: "1.5em",
            lineHeight: 1,
            color: "inherit",
    
            "& .MuiBreadcrumbs-ol": {
                listStyle: "none",
                padding: 0,
                margin: 0,
            },
            "& .MuiBreadcrumbs-li": {
                padding: 0,
                margin: 0,
            }
        }}>
            {items && items.map((item, index) => <MenuLink {...item} key={index} /> )}
        </Breadcrumbs>
    )
    
}

export default PagePath