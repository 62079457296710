import React from 'react'
import { Box } from "@mui/material"

const CoreModelGrid = ({minCols = 2, maxCols = 3, children}) => {

    return (
        <Box sx={{
            marginTop: "1.5em",
            marginBottom: "1.5em",

            display: "grid",
            columnGap: "1em",
            rowGap: "1em",
            gridTemplateColumns: {
                xs: "repeat("+minCols+", minmax(0, 1fr))",
                sm: "repeat("+maxCols+", minmax(0, 1fr))"
            }

        }}>
            {children}
        </Box>
    )
    
}

export default CoreModelGrid