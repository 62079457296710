import React from 'react'
import { getNode } from "../../utils"
import { SectionBase, ElementsGrid, LinkList } from "../"
import { Typography } from "@mui/material"

import BlockLinksItem from "./BlockLinksItem"

const templates = {
    "default": LinkList,
    "elementsGrid": ElementsGrid
}

const BlockLinks = ({node = {}}) => {
    const { title, titleHidden, links = [], layout } = getNode(node)

    const defaultLayout = Object.keys(templates)[0]
    const ListTemplate = layout && templates[layout] || templates[defaultLayout]

    return (
        <SectionBase>
            { title && !titleHidden && <Typography variant="h3">{title}</Typography> }
            <ListTemplate>
                { links.map(item => <BlockLinksItem {...item} layout={layout} key={item._key} />) }
            </ListTemplate>
        </SectionBase>
    )


    
}

export default BlockLinks