import React from "react"
import { List } from "@mui/material"

import {
    ModelGrid,
    ModelList,
    CoreContentGrid,
    TargetAudienceGrid
} from ".."

const templates = {
    "targetAudience": TargetAudienceGrid,
    "businessGoals": ModelGrid,
    "userTasks": ModelGrid,
    "coreContent": CoreContentGrid,
    "inwardPaths": ModelList,
    "forwardPaths": ModelList
}


const CategoryList = ({category = {}, type = "bullet", children, ...props}) => {

    const ListTemplate = templates && templates[category.iconColor] || List

    return (
        <ListTemplate>
            {children}
        </ListTemplate>
    )

}

export default CategoryList
