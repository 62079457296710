import React from 'react'
import { Box } from "@mui/material"

const SectionBase = ({expanded = true, sx = {}, onClick, children}) => {

    return (
        <Box component="section" aria-expanded={expanded} onClick={onClick} sx={{
            "&[aria-expanded=false]": {
                borderBottom: "1px solid",
                borderColor: "inherit"
            },
            "&[aria-expanded=false] + section": {
//                marginTop: "1.5em",
            },
            "& section .MuiTypography-h2": {
                fontSize: "1.25em",
                fontWeight: 600,
            }
        }}>
            {children}
        </Box>
    )


}


export default SectionBase
