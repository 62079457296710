export const getFileSize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const getUrl = ({url, slug = {}}) => {

    if (url) {
        return url
    }

    return slug && slug.current && "/" + slug.current
}

export const getLinks = (links) => {

    if (!links) {
        return
    }

    return links.map(item => getNode(item))

}

export const getMenu = (menu) => {

    if (!menu) {
        return
    }

    return menu.map(item => getNode(item))

}

export const getNode = ({menu, links, ...node}) => {

    return {
        ...node,
        links: links && getLinks(links),
        menu: menu && getMenu(menu),
        url: getUrl(node)
    }

}

export const getNodes = ({nodes}) => {
    return nodes && nodes.map(node => getNode(node))
}

export const getModelFromData = ({model}) => {
    if (!model) {
        return false
    }

    return getNode(model)
}