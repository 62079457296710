import React from 'react'
import { ButtonBase } from '@mui/material';

const MenuButton = ({children, onClick}) => {

    return (
        <ButtonBase  onClick={onClick} sx={{
            fontFamily: "inherit",
            fontSize: ".875em",
            fontWeight: 500,
            lineHeight: 1,
            border: 0,
            backgroundColor: "transparent",

            padding: ".875em 1em",
            border: "1px solid",

            "&:hover": {
                cursor: "pointer"
            }
        }}>
            {children}
        </ButtonBase>
    )
}

export default MenuButton