import React from 'react'
import PortableText from '@sanity/block-content-to-react'
import { Typography, Box } from '@mui/material';

const BlockDefault = (props) => {
    const { type, children } = props
    const { style = 'normal' } = props.node

    if (/^h\d/.test(style)) {
        const level = style.replace(/[^\d]/g, '')
        return <Typography variant={style}>{children}</Typography>
    }
    
    if (style === 'blockquote') {
        return <Typography component="blockquote" variant="quote">{children}</Typography>
    }

    if (style === 'normal') {
        return <Typography component="p" variant="p1">{children}</Typography>
    }


    // Fall back to default handling
    return PortableText.defaultSerializers.types.block(props)
}

export default BlockDefault