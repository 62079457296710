import React, { forwardRef } from 'react'
import { Box } from "@mui/material"

const BusinessGoal = forwardRef(({variant = "businessGoals", size = "100%", children}, ref) => {

    return (
        <Box sx={{
            width: "100%",
            height: size,
            margin: "0 auto"
        }}>
            <Box sx={{
                position: "relative",
                width: "100%",
                paddingBottom: "100%",
            }}>
                <Box sx={{
                    backgroundColor: (theme) => theme.palette.background[variant],
                    borderRadius: "50%",
                    position: "absolute",
                    padding: ".25em",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"

                }}>
                    {children}
                </Box>
            </Box>
        </Box>
    )

})

export default BusinessGoal