import React, { forwardRef } from 'react'
import { Box } from "@mui/material"
import shape from "./TargetAudience.svg"

const TargetAudienceModule = forwardRef(({variant = "targetAudience", height = "100%", children}, ref) => {

    return (
        <Box sx={{}}>
            <Box sx={{
                position: "relative",
                width: "100%",
                paddingBottom: "100%",
                backgroundImage: "url("+shape+")",
                backgroundSize: "100% 100%",
                backgroundPosition: "center",
            }}>
                <Box sx={{
 //                   backgroundColor: (theme) => theme.palette.background[variant],
                    position: "absolute",
                    padding: "3em",
                    textAlign: "center",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                }}>
                    {children}
                </Box>
            </Box>
        </Box>
    )


})

export default TargetAudienceModule