import React from 'react'
import { Box, List } from "@mui/material"

const MenuList = ({expanded, children}) => {
    return (
        <List component="ul" aria-expanded={expanded} sx={{
            listStyle: "none",
            padding: 0,
            margin: 0,
    
            display: "flex",
            flexDirection: "column",

            "&[aria-expanded=false]": {
                display: "none"
            },


            "& > li > ul": {
                marginTop: "1em"
            },
    
            "& > li + li": {
                marginTop: "1em"
            }
    
        }}>
            {children}
        </List>
    )
}

export default MenuList