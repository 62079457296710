import React from "react"
import CategoryListItem from "./CategoryListItem"

const BlockListItem = ({category = {}, type = "bullet", children, ...props}) => {

    if (category && category.icon) {
        return (
            <CategoryListItem {...props} category={category} type={type}>
                {children}
            </CategoryListItem>
        )
    }

    return (
        <li>
            {children}
        </li>
    )

}

export default BlockListItem
