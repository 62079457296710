import React from 'react'
import { getNode } from "../../utils"
import { SectionBase, FileModule } from "../"
import { Typography } from "@mui/material"
import BlockFilesItem from './BlockFilesItem'

import { CollapsibleSection, CollapsibleNote } from "../"

const templates = {
    "attachments": CollapsibleNote,
    "collapsibleAttachments": (props) => <CollapsibleNote {...props} collapsible={true} />,
    "section": CollapsibleSection,
    "collapsibleNote": (props) => <CollapsibleNote {...props} collapsible={true} />,
}


const BlockLinks = ({settings = {}, node = {}}) => {

    const { layout, title, titleHidden, files = [], ...props } = getNode(node)

    const defaultLayout = Object.keys(templates)[0]
    const SectionTemplate = templates && templates[layout] || templates[defaultLayout]

    return (
        <SectionTemplate {...props} title={titleHidden && undefined || title}>
            { files && files.map(item => <FileModule {...item} layout={layout} key={item._key} />)}
        </SectionTemplate>
    )

    
}

export default BlockLinks