import React from 'react'
import { Block, ArticleBody } from '../'

const ArticleBodyBlocks = ({children}) => {

    return (
        <Block>{children}</Block>
    )
    
}

export default ArticleBodyBlocks