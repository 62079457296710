import { useStaticQuery, graphql } from "gatsby"
import { getNode, getNodes } from "../utils"

const icons = [
    "audience",
    "tasks",
    "goals",
    "inward",
    "core",
    "forward",
]

export const useSiteSettings = ({data = {}, location = {}}) => {

    const siteSettingsQuery = graphql`
        query SiteSettingsQuery {
            site: sanityPage(_id: { regex: "/(drafts.|)home/" }) {
                title
                menu: _rawMenu(resolveReferences: { maxDepth: 7 })
                seo {
                    title 
                    description
                }
            }
        }
    `

    const { site } = useStaticQuery(siteSettingsQuery) || {}

    const siteTitle = site.title

    const siteSettings = getNode(site)

    // meta

    const { href, pathname = "/" } = location

    const menu = siteSettings.menu && siteSettings.menu.map(item => {

        return {
            ...item,
            selected: item.url === pathname
        }

    })

    const { model = {} } = data

    const title = pathname === "/" && siteTitle || model.title + " – " + siteTitle
    const description = model.seo && model.seo.description || model.description
    const canonicalUrl = location.href

    // path

    const path = [
        {
            title: siteTitle,
            url: "/"
        },

    ]

    return {
        title: title,
        description: description,
        canonicalUrl: canonicalUrl,
        root: "/",
        path: path,
        menu: menu,
        /*
        methods: methods,
        excercises: excercises,
        resources: resources
        */
    }
}