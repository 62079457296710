import React from 'react'
import Image from "gatsby-plugin-sanity-image"
import { Box, Typography } from "@mui/material"




const Media = ({layout, image, caption, credit}) => {

    let flexDirection = "column", mediaWidth = "100%"

    if (layout === "xs") {
        flexDirection = "row"
        mediaWidth = "25%"
    }

    if (layout === "small") {
        flexDirection = "row"
        mediaWidth = "50%"
    }

    if (layout === "medium") {
        flexDirection = "row"
        mediaWidth = {
            xs: "75%",
            md: "75%"
        }
    }

    return (
        <Box component="figure" sx={{
            display: "flex",
            flexDirection: flexDirection,
            gap: flexDirection === "row" && "1em" || ".5em",
            padding: 0,
            margin: 0,
            marginBottom: "1.5em"
        }}>

            <Box sx={{
                flexGrow: 0,
                flexShrink: 0,
                flexBasis: mediaWidth

            }}>
                <Image
                    // pass asset, hotspot, and crop fields
                    {...image}
                    // tell Sanity how large to make the image (does not set any CSS)
                    width={1000}
                    // style it how you want it
                    style={{
                        display: "block",
                        maxWidth: "100%",
                        maxHeight: "100%",
                    }} />
            </Box>
            <Box component="figcaption">
                <Typography variant="caption">{caption}</Typography>
                {" "}
                { credit && <Typography variant="caption">Foto: {credit}</Typography> }
            </Box>
        </Box>
    )
}

export default Media