import React from 'react'
import { Box } from "@mui/material"

const ListSection = ({children}) => {

    return (
        <Box sx={{
            marginTop: "1.5em",
            marginBottom: "1.5em",

            display: "flex",
            flexDirection: "column",

                /*

            "& > [data-module=link]": {
                borderTop: "1px solid",
                marginTop: "1.5em",
                paddingTop: "1em",
            },


            "& > * + *": {
                borderTop: "1px solid",
                marginTop: "1em",
                paddingTop: "1em",
            }
            */
            
        }}>
            {children}
        </Box>
    )


}


export default ListSection
