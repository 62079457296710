import React from 'react'
import { useSiteSettings } from "../hooks/useSiteSettings"
import { Helmet } from "react-helmet";
import { DefaultTheme} from '../theme/'
import { PageBase, PageHeader, PageFooter, PageBody } from '../components'


const BaseLayout = ({backgroundColor, data = {}, location = {}, children}) => {

    const settings = useSiteSettings({data, location})

    const { menu, path, title, description, canonicalUrl } = settings

    return (
        <DefaultTheme>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <PageBase backgroundColor={backgroundColor}>
                <PageHeader path={path} menu={menu}></PageHeader>
                <PageBody>
                    {children}
                </PageBody>
                <PageFooter menu={menu}></PageFooter>
            </PageBase>
        </DefaultTheme>
    )        

}

export default BaseLayout
