import React from 'react'
import { Box } from '@mui/material';

const ArticleHeader = ({children}) => {

    return (
        <Box component="header" sx={{
            width: "100%",
            marginTop: "3em",
            marginBottom: "1.5em",
        }}>
            {children}
        </Box>
    )


}


ArticleHeader.defaultProps = {
}

export default ArticleHeader
