import React from 'react'
import { Box } from '@mui/material';


const ArticleBase = ({children}) => {

    return (
        <Box component="article">
            {children}
        </Box>
    )
    
}

export default ArticleBase