import React, { useState } from 'react'
import Block from './Block'

import { CollapsibleSection, CollapsibleNote } from "../"

const templates = {
    "section": CollapsibleSection,
    "collapsibleSection": (props) => <CollapsibleSection {...props} collapsible={true} />,
    "note": CollapsibleNote,
    "collapsibleNote": (props) => <CollapsibleNote {...props} collapsible={true} />,
}

const BlockSection = ({node}) => {
    const { layout = "section", title, titleHidden, body, ...props } = node

    const defaultLayout = Object.keys(templates)[0]
    const SectionTemplate = templates && templates[layout] || templates[defaultLayout]

    return (
        <SectionTemplate {...props} title={titleHidden && undefined || title}>
            <Block {...props} component={false}>{body}</Block>
        </SectionTemplate>
    )


}

export default BlockSection
