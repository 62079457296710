import React from 'react'
import DefaultTheme from '../theme/DefaultTheme'
import { BaseLayout } from "."
import { Typography } from "@mui/material"
import { ArticleBase, CategoryHeader, ArticleHeader, ArticleFooter, ArticleBody, ArticleGrid } from '../components'

const DebugLayout = ({data = {}, location = {}, title, description, children}) => {

    return (
        <DefaultTheme>
            <BaseLayout data={data} location={location}>
                <ArticleBase>
                    <ArticleHeader>
                        <ArticleGrid>
                            <Typography variant="a1" component="h1">{title}</Typography>
                            <Typography variant="a2" component="h2">{description}</Typography>
                        </ArticleGrid>
                    </ArticleHeader>
                    <ArticleBody>
                        <ArticleGrid>
                            {children}
                        </ArticleGrid>
                    </ArticleBody>
                </ArticleBase>
            </BaseLayout>
        </DefaultTheme>
    )        

}

export default DebugLayout
