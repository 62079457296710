import React from 'react'
import sanityConfig from '../../../sanity-config.js'
import imageUrlBuilder from '@sanity/image-url'
import { MediaModule } from '../';

const builder = imageUrlBuilder(sanityConfig.api)

const urlFor = (source) => {
    return builder.image(source)
}


const BlockImage = ({node}) => {

    return (
        <MediaModule {...node} />
    )
}

export default BlockImage