import React from 'react'
import { Box } from '@mui/material';
import {
    TargetAudience,
    UserTask,
    BusinessGoal,
    InwardPath,
    ForwardPath,
    CoreContent,
} from "../../icons"

const CategoryHeader = ({justifyContent = "center", iconColor, icon = {}, children}) => {

    const imageUrl = icon && icon.asset && icon.asset.url

    if (iconColor === "targetAudience") {
        justifyContent = "flex-end"
    }

    if (imageUrl) {
        return (
            <Box sx={{
                overflow: "visible",
                backgroundImage: "url("+imageUrl +")",
                backgroundSize: {
                    xs: "200% 200%",
                    xs: "90ch 90ch",
                },
                backgroundPosition: "bottom center",
                backgroundRepeat: "no-repeat",
                display: "flex",
                flexDirection: "column",
                justifyContent: justifyContent,
                alignItems: "center",
                width: {
                    xs: "auto",
                    lg: "100%"
                },
                height: "90ch",
                minHeight: "60ch",
                maxHeight: {
                    xs: "100vh",
                    lg: "auto"
                },
                marginTop: "-10em",
                paddingTop: "10em",
                paddingBottom: "10em",
//                marginBottom: "-10em",
                marginLeft: "auto",
                marginRight: "auto",
            }}>
                {children}
            </Box>
        )
    }
    
    return children


}


CategoryHeader.defaultProps = {
}

export default CategoryHeader
