import React from "react"
import CategoryList from "./CategoryList"
import { Typography } from "@mui/material"

const BlockList = ({category = {}, type, variant = "list1", children, ...props}) => {

    if (category && category.icon) {
        return (
            <CategoryList {...props} category={category} type={type}>
                {children}
            </CategoryList>
        )
    }

    if (type === "number") {
        return (
            <Typography component="ol" variant={variant}>
                {children}
            </Typography>
        )
    }

    return (
        <Typography component="ul" variant={variant}>
            {children}
        </Typography>
    )

}

export default BlockList
