import React, { forwardRef } from 'react'
import { Box } from '@mui/material';
import shape from "./ForwardPath.svg"


const ForwardPath = forwardRef(({size = "2em", children}, ref) => {

    return (
        <Box sx={{
            display: "flex",
            "&:before": {
                flexShrink: 0,
                content: '""',
                display: "block",
                width: "2em",
                backgroundImage: "url("+shape+")",
                backgroundSize: "200% 100%",
                backgroundPosition: "left",
                backgroundRepeat: "no-repeat",
            },
    
            "&:after": {
                flexShrink: 0,
                content: '""',
                display: "block",
                width: "2em",
                backgroundImage: "url("+shape+")",
                backgroundSize: "200% 100%",
                backgroundPosition: "right",
                backgroundRepeat: "no-repeat",
            }            
        }}>
            <Box sx={{
                backgroundColor: (theme) => theme.palette.background.forwardPaths,
                flexGrow: 1,
                padding: "1em 1em 1em 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: size,
            }}>
                {children}
            </Box>

        </Box>
    )  

})


export default ForwardPath