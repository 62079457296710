import React from 'react'
import Image from "gatsby-plugin-sanity-image"
import { Box, Typography } from "@mui/material"
import { Link } from "gatsby"

const ElementsModule = ({url, title, description, category = {} }) => {

    const icon = category.icon
    const iconColor = category.iconColor

    const imageUrl = icon && icon.asset && icon.asset.url

    let justifyContent = "center"

    if (iconColor === "targetAudience") {
        justifyContent = "flex-end"
    }

    return (
        <Box component={Link} to={url} sx={{
            position: "relative",
            backgroundImage: "url("+imageUrl +")",
            backgroundSize: "100% 100%",
            backgroundPosition: "bottom center",
            backgroundRepeat: "no-repeat",
            paddingBottom: "100%",
            color: "inherit",

            "&:hover h4": {
                textDecoration: "underline"
            }

        }}>
            <Box sx={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: justifyContent,
                padding: "3em 1em",
                textAlign: "center"
            }}>
                <Typography variant="h4">{title}</Typography>
            </Box>
        </Box>
    )


}


export default ElementsModule
