import React, { forwardRef } from 'react'
import { Box } from "@mui/material"
import shape from "./InwardPath.svg"

const InwardPath = forwardRef(({size = "2em", children}, ref) => {
    
    return (
        <Box sx={{
            display: "flex",
            "&:before": {
                content: '""',
                flexShrink: 0,
                display: "block",
                width: "1em",
                height: "100%",
                backgroundImage: "url("+shape+")",
                backgroundSize: "200% 100%",
                backgroundPosition: "left",
                backgroundRepeat: "no-repeat",
            },
    
            "&:after": {
                content: '""',
                display: "block",
                flexShrink: 0,
                width: "2em",
                backgroundImage: "url("+shape+")",
                backgroundSize: "200% 100%",
                backgroundPosition: "right",
                backgroundRepeat: "no-repeat",
            }            
        }}>
            <Box sx={{
                backgroundColor: (theme) => theme.palette.background.inwardPaths,
                flexGrow: 1,
                padding: "1em",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: size,
            }}>
                {children}
            </Box>

        </Box>
    )

})

export default InwardPath