import React from 'react'
import DefaultTheme from '../theme/DefaultTheme'
import { BaseLayout } from "."
import { Typography } from "@mui/material"
import { ArticleBase, CategoryHeader, ArticleHeader, ArticleFooter, ArticleBody, ArticleGrid, Block, ArticleBodyBlocks, ArticleFooterBlocks, Heading } from '../components'
import { getModelFromData } from "../utils"

import palette from "../theme/theme.palette"

const TopicLayout = ({data = {}, location = {}}) => {

    const { title, backgroundColor, category = {}, description, body, footer } = getModelFromData(data)

    const bgColor = backgroundColor && palette.background[backgroundColor] || category && category.backgroundColor && palette.background[category.backgroundColor]

    return (
        <DefaultTheme>
            <BaseLayout backgroundColor={bgColor} data={data} location={location}>
                <ArticleBase>
                    <CategoryHeader {...category}>
                        <ArticleHeader>
                            <ArticleGrid>
                                <Typography variant="a1" component="h1">{title}</Typography>
                                <Typography variant="a2" component="h2">{description}</Typography>
                            </ArticleGrid>
                        </ArticleHeader>
                    </CategoryHeader>
                        <ArticleBody>
                            <ArticleGrid>
                                <ArticleBodyBlocks>{body}</ArticleBodyBlocks>
                            </ArticleGrid>
                        </ArticleBody>
                        { footer && 
                            <ArticleFooter>
                                <ArticleGrid>
                                    <ArticleFooterBlocks>{footer}</ArticleFooterBlocks>
                                </ArticleGrid>
                            </ArticleFooter>
                        }
                    </ArticleBase>
            </BaseLayout>
        </DefaultTheme>
    )        

}

export default TopicLayout
