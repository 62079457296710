import React from "react"
import { ListItem } from "@mui/material"

import {
    TargetAudienceModule,
    UserTaskModule,
    BusinessGoalModule,

    InwardPathModule,
    ForwardPathModule,
    CoreContentModule
} from ".."

const templates = {
    "targetAudience": TargetAudienceModule,
    "businessGoals": BusinessGoalModule,
    "userTasks": UserTaskModule,
    "coreContent": CoreContentModule,
    "inwardPaths": InwardPathModule,
    "forwardPaths": ForwardPathModule
}

const CategoryListItem = ({category = {}, type = "bullet", children, ...props}) => {

    const ListItemTemplate = templates && templates[category.iconColor] || ListItem

    return (
        <ListItemTemplate>
            {children}
        </ListItemTemplate>
    )

}

export default CategoryListItem
