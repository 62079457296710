import React from 'react'
import { ModelGrid } from ".."

const TargetAudienceGrid = ({minCols = 1, maxCols = 2, children}) => {

    return (
        <ModelGrid minCols={minCols} maxCols={maxCols}>
            {children}
        </ModelGrid>
    )
    
}

export default TargetAudienceGrid