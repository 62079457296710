import React from "react"
import { Box } from '@mui/material';

const PageBody = ({children}) => {

    return (
        <Box sx={{
            position: "relative",
            zIndex: 1
        }}>
            {children}
        </Box>
    )

}

export default PageBody