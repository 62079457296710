import React from 'react'
import Image from "gatsby-plugin-sanity-image"
import { Box, Typography } from "@mui/material"
import { Link } from "gatsby"

const LinkResource = ({type, url, title, description, author, publisher, year, image, ...props}) => {

    return (
        <Box data-module="link" sx={{
            display: "flex",
            alignItems: "flex-end",
            width: "100%",
            gap: "1em",
            borderTop: "1px solid",
            paddingTop: "1em",
            marginBottom: "1.5em",
        }}>

            { image && <Box sx={{
                flexGrow: 0,
                flexShrink: 0,
                flexBasis: "5em",

                width: "5em",
                height: "5em",

                marginRight: "1em",

                padding: 0,
                margin: 0,

                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",                
            }}>

                <Image
                    // pass asset, hotspot, and crop fields
                    {...image}
                    // tell Sanity how large to make the image (does not set any CSS)
                    width={500}
                    // style it how you want it
                    style={{
                        border: "1px solid",
                        display: "block",
                        maxWidth: "100%",
                        maxHeight: "100%",
                    }}
            />

            </Box> }

            <Box sx={{
                flexGrow: 1,
                flexShrink: 1,
                display: "flex",
                flexDirection: "column",
            }}>
                    <Typography variant="subtitle1" component={Link} to={url} sx={{
                        color: "inherit"
                    }}>{title}</Typography>
                    { author && <Typography variant="author">{author}</Typography> }
                    { description && <Typography variant="body2">{description}</Typography> }
                </Box>
        </Box>
    )


}

export default LinkResource
